import React from "react";

type TitleProps = {
  className: string;
};

export function Title({ className }: TitleProps): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="840px"
      height="446px"
      viewBox="0 0 840 446"
    >
      <g>
        <g>
          <path
            d="M81.1,240.04c16.69,22.09,30.17,46.29,44.94,69.64c7.51,11.87,15.47,23.39,24.15,34.44
              c8.33,10.61,17.07,20.87,24.41,32.22c6.96,10.77,24.28,0.76,17.27-10.09c-7.6-11.76-16.6-22.5-25.3-33.45
              c-8.43-10.6-16.01-21.78-23.25-33.21c-14.78-23.35-28.26-47.56-44.94-69.64c-3.25-4.3-8.63-6.54-13.68-3.59
              C80.45,228.84,77.82,235.7,81.1,240.04L81.1,240.04z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M103.88,237.96c-7.61-20.6-12.03-42.81-9.94-64.82c0.95-10.02,3.81-20.1,9.1-28.7c4.14-6.74,11.22-15.49,19.56-16.85
              c1.45-0.24,2.98-0.2,4.48,0.33c0.68,0.24,3.83,2.29,2.59,1.33c3.27,2.55,3.7,3.39,5.69,7.02c2.84,5.18,5.12,14.64,5.94,20.98
              c2.06,16.04,1.15,32.41,0.11,48.5c-1.7,26.27-10.52,49.73-23.14,72.62c-6.22,11.28,11.05,21.38,17.27,10.09
              c13.45-24.4,23-49.53,25.47-77.45c1.69-19.09,2.4-38.6-0.23-57.63c-2.69-19.48-11.79-44.5-35-45.89
              c-12.48-0.74-23.97,7.11-31.93,16.06c-8.49,9.53-14.3,21.03-17.33,33.42c-6.91,28.31-1.89,59.33,8.07,86.29
              c1.87,5.06,6.78,8.5,12.3,6.98C101.77,248.93,105.76,243.06,103.88,237.96L103.88,237.96z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M285.24,227.83c-4.49-22.81-11.25-49.6-31.15-63.94c-10.79-7.77-25.35-8.88-37.67-4.33
              c-12.54,4.63-21.23,14.23-26.11,26.5c-8.64,21.71-5.24,49.75,5.25,70.26c11.91,23.31,44.4,51.02,71.11,32.83
              c14.63-9.96,20.23-27.41,21.89-44.25c1.52-15.39-0.47-33.74-9.96-46.51c-3.22-4.33-8.66-6.53-13.68-3.59
              c-4.27,2.5-6.83,9.32-3.59,13.68c6.1,8.21,7.74,18.54,7.63,29.01c-0.11,10.23-1.92,21.64-7.82,29.65c-3,4.08-6.92,6.97-11.5,7.67
              c-2.71,0.42-7.54-0.62-11.11-2.3c-9.91-4.67-18.13-13.37-23.7-22.67c-2.93-4.9-3.47-6.28-5.05-11.14
              c-1.45-4.49-2.53-9.09-3.28-13.75c-1.18-7.3-1.12-18.73,0.69-26.19c1.27-5.24,2.06-7.29,4.83-11.71c1.64-2.62,4.24-5.2,7.36-7
              c6.81-3.94,15.84-4.17,21.69-0.81c3.35,1.93,6.96,5.63,9.1,8.76c2.8,4.11,5.65,9.72,7.07,13.37c4,10.25,6.57,20.97,8.69,31.75
              c1.04,5.3,7.37,8.34,12.3,6.98C283.79,238.61,286.28,233.14,285.24,227.83L285.24,227.83z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M289.61,105.85c4.22,20.17,4.8,40.78,6.43,61.26c1.43,17.97,3.27,31.57,5.98,48.77c2.12,13.48,5.57,29.46,16.31,38.89
              c11.92,10.47,28.76,9.87,42.83,4.52c15.27-5.8,29.74-15.42,40.92-27.37c8.78-9.39-5.33-23.57-14.14-14.14
              c-8.2,8.77-18.08,15.74-28.99,20.89c-4.84,2.28-11.07,4.3-15.63,4.45c-5.13,0.17-9.31-0.74-11.97-3.52
              c-2.89-3.02-4.99-7.71-6.48-12.65c-1.86-6.17-3.01-12.54-3.94-18.9c-1.95-13.32-3.94-28.63-5.03-42.8
              c-1.66-21.65-2.53-43.4-6.99-64.71c-1.11-5.29-7.33-8.35-12.3-6.98C291.11,95.06,288.5,100.55,289.61,105.85L289.61,105.85z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M389.5,96.44c0.12,18.93,0.68,37.86,1.61,56.77c0.82,16.53,1.41,33.24,3.24,49.69c1.72,15.43,6.15,30.08,19.26,39.58
              c11.61,8.41,26.82,8.39,39.8,3.38c14.39-5.56,27.04-15.24,37.65-26.33c8.9-9.3-5.22-23.47-14.14-14.14
              c-11.53,12.06-35.31,32.56-52.07,20.63c-8.71-6.2-10.17-18.22-10.95-28.09c-1.17-14.88-2.04-29.81-2.78-44.72
              c-0.94-18.91-1.49-37.84-1.61-56.77C409.42,83.57,389.42,83.54,389.5,96.44L389.5,96.44z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M491.22,86.3c-10.52,34.96-21.07,73.19-14.44,109.87c2.75,15.2,9.77,31.44,24.49,38.45c13.03,6.21,28.03,5.06,41.38,0.41
              c14.75-5.14,28.84-13.7,39.66-25.03c8.89-9.31-5.23-23.48-14.14-14.14c-7.55,7.91-16.18,13.75-26.89,18.39
              c-3.86,1.67-10.51,3.76-15.71,4.49c1.13-0.16-1.92,0.15-2.61,0.18c-1.38,0.07-2.76,0.07-4.13,0.01c-0.68-0.03-1.35-0.08-2.03-0.15
              c0.44,0.05,1.7,0.32-0.32-0.06c-1.47-0.28-2.88-0.68-4.31-1.11c-3.01-0.9-6.97-3.81-9.2-7.22c-3.7-5.66-5.52-12.23-6.77-18.83
              c-2.9-15.31-1.4-31.68,1.17-46.93c3.03-17.98,7.88-35.58,13.13-53.02C514.23,79.26,494.93,73.99,491.22,86.3L491.22,86.3z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M491.53,156.18c21.96-5.4,42.7-14.3,63.58-22.79c5-2.03,8.54-6.66,6.98-12.3c-1.3-4.74-7.27-9.03-12.3-6.98
              c-20.88,8.49-41.62,17.39-63.58,22.79C473.72,139.97,479.01,159.26,491.53,156.18L491.53,156.18z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M491.52,101.19c27.78-15.53,57.86-23.42,89.72-22.63c12.87,0.32,12.87-19.68,0-20c-35.32-0.88-69.05,8.16-99.82,25.36
              C470.19,90.2,480.26,107.48,491.52,101.19L491.52,101.19z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M305.01,337.86c37.7-9.75,76.14-16.26,114.73-21.21c39.04-5.01,78.23-9.93,117.41-13.66
              c39.56-3.77,78.96-3.39,118.64-2.34c19.66,0.52,39.32,1.1,58.99,1.34c11.56,0.14,23.12-0.14,34.66,0.79
              c2.46,0.2,4.9,0.45,7.35,0.73c2,0.23,0.53,0.06,0.12,0c0.77,0.1,1.53,0.22,2.29,0.34c1.35,0.21,2.69,0.44,4.03,0.7
              c2.23,0.43,4.45,0.93,6.64,1.56c1.11,0.32,2.19,0.72,3.28,1.07c2.18,0.71-1.03-0.81,0.76,0.36c0.33,0.22,0.72,0.46,1.09,0.6
              c1.3,0.52,0.11,1.04-0.39-0.43c0.05,0.16,0.69,0.6,0.77,0.69c0.88,1.04-0.58-0.76-0.66-0.92c-0.5-0.97-0.63-3.42,0.08-4.58
              c-0.2,0.33-0.34,0.74-0.62,1.02c-0.83,0.86,1.82-1.7,0.02-0.13c-0.87,0.75-1.64,1.6-2.55,2.32c0.99-0.78-1.93,1.28-2.42,1.59
              c-1.93,1.23-3.93,2.35-5.94,3.44c-11.32,6.14-1.23,23.41,10.09,17.27c9.51-5.16,23.55-13.86,20.37-26.83
              c-2.37-9.63-13.18-13.59-21.78-15.61c-25.54-6.01-52.87-3.81-78.82-4.38c-42.2-0.94-84.58-3.38-126.76-0.91
              c-42.28,2.48-84.35,8.51-126.39,13.46c-47.18,5.56-94.27,12.51-140.31,24.41C287.24,321.79,292.52,341.09,305.01,337.86
              L305.01,337.86z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M315.71,281.39c1.25,8.59,6.08,23.76,17.81,19.83c3.51-1.18,6.45-3.61,9.01-6.21c2.26-2.3-1.28-5.84-3.54-3.54
              c-2.23,2.27-4.89,4.54-7.76,5.2c-0.15,0.04-0.31,0.07-0.47,0.09c0.28-0.03,0.27-0.03-0.02,0c-0.3,0.03-0.59,0.04-0.89,0.03
              c-0.22-0.01-0.43-0.02-0.64-0.04c0.63,0.07-0.25-0.07-0.37-0.1c-0.27-0.07-0.53-0.16-0.79-0.26c0.56,0.22-0.25-0.14-0.35-0.2
              c-0.25-0.14-0.49-0.3-0.72-0.46c-0.37-0.25,0.17,0.19-0.27-0.22c-1.72-1.61-2.94-3.65-3.8-5.88c-1.19-3.07-1.9-6.32-2.38-9.58
              c-0.19-1.34-1.89-2.07-3.08-1.75C316.02,278.71,315.51,280.05,315.71,281.39L315.71,281.39z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M325.34,289.1c5.42-1.45,10.33-4.2,14.9-7.4c1.07-0.75,1.67-2.22,0.9-3.42c-0.68-1.06-2.28-1.7-3.42-0.9
              c-2.16,1.51-4.38,2.94-6.72,4.17c-0.58,0.3-1.17,0.59-1.76,0.87c-0.3,0.14-0.6,0.27-0.9,0.4c0.4-0.17-0.46,0.19-0.54,0.22
              c-1.24,0.49-2.5,0.9-3.79,1.24c-1.26,0.34-2.16,1.8-1.75,3.08C322.68,288.65,323.98,289.46,325.34,289.1L325.34,289.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M320.57,279.31c4.04-1.85,7.94-3.99,11.92-5.96c1.17-0.58,1.59-2.34,0.9-3.42c-0.78-1.2-2.16-1.52-3.42-0.9
              c-3.98,1.97-7.88,4.11-11.92,5.96c-1.19,0.55-1.58,2.36-0.9,3.42C317.94,279.65,319.3,279.9,320.57,279.31L320.57,279.31z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M345.92,292.65c1.35-1.04,2.6-2.19,3.67-3.52c1.33-1.64,2.62-3.55,2.69-5.74c0.07-2.46-1.91-4.96-4.49-4.9
              c-2.33,0.05-4.26,1.9-5.41,3.78c-1.11,1.81-1.47,4.17-1.4,6.26c0.07,2.3,0.66,4.6,1.79,6.62c2.26,4.05,7.08,6.62,11.71,5.73
              c4.9-0.94,7.55-6.09,8.5-10.52c0.27-1.28-0.38-2.76-1.75-3.08c-1.26-0.29-2.78,0.38-3.08,1.75c-0.18,0.83-0.39,1.64-0.68,2.44
              c-0.07,0.21-0.15,0.42-0.24,0.62c0.04-0.09,0.2-0.42-0.01,0c-0.2,0.41-0.4,0.82-0.63,1.22c-0.19,0.32-0.41,0.62-0.61,0.93
              c0.27-0.41,0.11-0.14,0.03-0.04c-0.13,0.14-0.25,0.29-0.38,0.43c-0.22,0.23-0.45,0.44-0.68,0.65c-0.45,0.41,0.44-0.25-0.08,0.05
              c-0.18,0.11-0.36,0.23-0.54,0.33c-0.13,0.07-0.27,0.14-0.4,0.2c0.58-0.29,0.06-0.04-0.08,0.01c-0.29,0.09-0.59,0.15-0.89,0.21
              c0.69-0.13-0.36-0.01-0.57-0.02c-0.17-0.01-0.33-0.02-0.5-0.03c0.65,0.05-0.24-0.06-0.37-0.09c-0.37-0.09-0.73-0.21-1.09-0.34
              c-0.7-0.25,0.38,0.22-0.31-0.14c-0.15-0.08-0.31-0.15-0.46-0.24c-0.19-0.1-0.37-0.21-0.55-0.32c0.01,0-0.56-0.39-0.27-0.17
              c0.29,0.22-0.24-0.21-0.24-0.21c-0.21-0.19-0.41-0.39-0.6-0.6c-0.1-0.1-0.19-0.21-0.28-0.32c-0.45-0.51,0.23,0.4-0.13-0.17
              c-0.19-0.31-0.39-0.6-0.56-0.92c-0.1-0.19-0.2-0.39-0.29-0.58c-0.32-0.62,0.14,0.46-0.09-0.21c-0.14-0.42-0.29-0.83-0.39-1.26
              c-0.09-0.36-0.16-0.72-0.22-1.08c0.1,0.59-0.01-0.13-0.02-0.21c-0.01-0.15-0.02-0.29-0.03-0.44c-0.02-0.44-0.02-0.88,0.01-1.32
              c0.01-0.15,0.02-0.29,0.03-0.44c0.03-0.29,0.03-0.29-0.01,0.02c0.02-0.14,0.05-0.29,0.08-0.43c0.07-0.35,0.16-0.7,0.26-1.05
              c0.02-0.05,0.13-0.53,0.18-0.54c-0.01,0-0.3,0.54-0.02,0.1c0.18-0.28,0.34-0.56,0.53-0.84c-0.3,0.43-0.12,0.14-0.03,0.06
              c0.18-0.18,0.37-0.37,0.54-0.56c0.4-0.44-0.42,0.22,0.08-0.08c0.1-0.06,0.21-0.12,0.31-0.19c0.46-0.32-0.49,0.14,0.03-0.01
              c0.52-0.15-0.47,0.03-0.12,0.02c0.2-0.01-0.49-0.13-0.58-0.18c0.05,0.03,0.1,0.05,0.14,0.08c-0.27-0.22-0.3-0.24-0.09-0.04
              c-0.2,0-0.15-0.32,0.03,0.09c0.09,0.17,0.05,0.08-0.11-0.26c0.03,0.09,0.05,0.19,0.07,0.28c-0.13-0.74-0.06-0.26-0.05-0.07
              c0.03,0.47,0.15-0.51,0-0.04c-0.05,0.16-0.1,0.32-0.14,0.48c-0.14,0.51,0.22-0.38-0.02,0.06c-0.21,0.39-0.42,0.78-0.67,1.16
              c-0.07,0.11-0.15,0.22-0.23,0.33c-0.08,0.11-0.16,0.22-0.24,0.32c0.19-0.24,0.19-0.25,0.02-0.03c-0.51,0.59-1.02,1.17-1.57,1.71
              c-0.24,0.23-0.48,0.46-0.73,0.68c-0.17,0.16-1.06,0.86-0.29,0.27c-1.07,0.82-1.64,2.15-0.9,3.42
              C343.11,292.8,344.84,293.48,345.92,292.65L345.92,292.65z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M357.89,285.22c0.96,3.65,2.53,7.09,4.07,10.52c0.5,1.12,2.09,1.48,3.12,1.02c1.22-0.53,1.65-1.68,1.45-2.95
              c-0.64-3.94-2.19-7.96-0.72-11.89c-0.21,0.55,0.23-0.46,0.3-0.59c0.15-0.29,0.31-0.58,0.49-0.86c0.52-0.85,0.69-1.08,1.34-1.71
              c0.12-0.11,0.24-0.23,0.36-0.33c-0.3,0.25-0.01,0.01,0.06-0.03c0.13-0.07,0.26-0.15,0.39-0.22c-0.42,0.23-0.05-0.01,0.1-0.01
              c0.03-0.01,0.07-0.02,0.1-0.03c-0.33,0.03-0.34,0.04-0.05,0.03c0.05-0.03,0.26,0.03,0.3,0.02c-0.57,0.07-0.44-0.1-0.24-0.02
              c0.12,0.05,0.28,0.09,0.41,0.12c0.43,0.08-0.29-0.25-0.15-0.06c0.05,0.06,0.18,0.09,0.25,0.13c0.1,0.06,0.19,0.14,0.3,0.19
              c-0.06-0.03-0.43-0.41-0.17-0.13c0.15,0.16,0.32,0.31,0.47,0.46c0.09,0.09,0.16,0.19,0.25,0.29c-0.23-0.26-0.21-0.3-0.02,0
              c0.25,0.4,0.49,0.81,0.7,1.24c0.06,0.13,0.12,0.27,0.18,0.4c-0.03-0.07-0.19-0.52-0.04-0.09c0.1,0.3,0.22,0.58,0.32,0.88
              c0.8,2.36,1.21,4.84,1.6,7.3c0.34,2.15,0.49,4.49,1.3,6.53c0.5,1.25,1.67,2.13,3.08,1.75c1.19-0.33,2.25-1.81,1.75-3.08
              c-0.81-2.04-0.96-4.39-1.3-6.53c-0.44-2.76-0.9-5.57-1.92-8.18c-1.01-2.59-2.57-5.13-5.38-5.98c-4.02-1.23-7.24,2.33-8.88,5.58
              c-1.17,2.33-1.66,4.87-1.48,7.47c0.2,2.93,1.03,5.76,1.5,8.65c1.52-0.64,3.05-1.28,4.57-1.93c-1.37-3.05-2.72-6.09-3.57-9.33
              C361.89,280.78,357.07,282.1,357.89,285.22L357.89,285.22z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M414.17,256.77c1.18,11.03,3.66,21.87,4.84,32.9c0.13,1.24,0.85,2.3,2.17,2.46c1.17,0.14,2.47-0.56,2.74-1.79
              c0.94-4.25,1.52-8.57,2.55-12.8c1.02-4.18,2.94-7.92,4.38-11.95c0.46-1.27-0.51-2.74-1.75-3.08c-1.4-0.38-2.61,0.47-3.08,1.75
              c-2.35,6.37-4.65,14.23-2.33,20.93c1.03,2.99,3.09,6,6.42,6.61c3.08,0.56,6.01-0.77,8.41-2.62c2.71-2.1,4.88-4.84,6.17-8.02
              c0.51-1.25-0.55-2.75-1.75-3.08c-1.43-0.39-2.57,0.49-3.08,1.75c0.17-0.41-0.23,0.46-0.27,0.54c-0.17,0.32-0.35,0.63-0.55,0.94
              c-0.39,0.63-0.75,1.11-1.18,1.61c-0.93,1.09-1.97,2.09-3.1,2.82c-0.28,0.18-0.57,0.36-0.87,0.52c-0.09,0.05-0.77,0.37-0.54,0.28
              c-0.33,0.13-0.67,0.24-1.02,0.32c-0.17,0.04-0.34,0.07-0.51,0.1c0.25-0.04,0.22-0.04-0.09-0.02c-0.16,0.01-0.33,0-0.49-0.01
              c0.26,0.05,0.24,0.05-0.06-0.01c-0.02,0-0.73-0.22-0.34-0.06c-0.59-0.24-0.83-0.43-1.21-0.89c-2.14-2.56-2.03-6.65-1.65-9.75
              c0.45-3.64,1.58-7.21,2.85-10.64c-1.61-0.44-3.21-0.89-4.82-1.33c-1.44,4.02-3.36,7.77-4.38,11.95c-1.03,4.23-1.62,8.55-2.55,12.8
              c1.64,0.22,3.27,0.44,4.91,0.66c-1.18-11.03-3.67-21.87-4.84-32.9c-0.14-1.34-1.05-2.5-2.5-2.5
              C415.43,254.27,414.03,255.42,414.17,256.77L414.17,256.77z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M439.88,275.24c1.47,5.14,1.66,11.01,5.5,15.13c0.89,0.96,2.64,0.97,3.54,0c0.94-1.03,0.95-2.51,0-3.54
              c-0.1-0.11-0.2-0.22-0.3-0.34c-0.1-0.11-0.31-0.47-0.01,0.01c-0.15-0.23-0.32-0.46-0.46-0.69c-0.3-0.49-0.54-1-0.79-1.52
              c-0.13-0.27,0.15,0.41-0.01-0.04c-0.06-0.16-0.12-0.31-0.18-0.46c-0.1-0.28-0.2-0.57-0.29-0.86c-0.2-0.62-0.37-1.24-0.53-1.87
              c-0.6-2.37-0.97-4.8-1.64-7.15c-0.36-1.26-1.78-2.16-3.08-1.75C440.35,272.58,439.5,273.89,439.88,275.24L439.88,275.24z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M440.8,259.32c-0.2,0.23-0.4,0.47-0.56,0.74c-0.16,0.26-0.27,0.58-0.38,0.84c-0.04,0.09-0.06,0.18-0.08,0.28
              c-0.05,0.26-0.08,0.52-0.11,0.79c-0.01,0.06-0.01,0.12-0.01,0.17c0,0.45,0.12,0.87,0.34,1.26c0.22,0.38,0.52,0.67,0.9,0.9
              c0.15,0.08,0.29,0.17,0.44,0.26c0.26,0.16,0.55,0.29,0.84,0.38c0.69,0.21,1.4,0.08,2.09-0.1c0.33-0.08,0.61-0.23,0.85-0.45
              c0.27-0.18,0.49-0.41,0.64-0.7c0.08-0.2,0.17-0.4,0.25-0.6c0.12-0.44,0.12-0.89,0-1.33c-0.03-0.16-0.05-0.32-0.09-0.48
              c-0.08-0.33-0.23-0.65-0.37-0.95c-0.06-0.12-0.13-0.23-0.21-0.34c-0.23-0.3-0.45-0.6-0.72-0.88c-0.31-0.31-0.68-0.52-1.1-0.64
              c-0.44-0.12-0.89-0.12-1.33,0c-0.24,0.1-0.5,0.19-0.73,0.31c-0.03,0.02-0.06,0.04-0.09,0.06c-0.12,0.09-0.42,0.28-0.57,0.46
              c-0.07,0.09-0.14,0.17-0.2,0.26c-0.07,0.09-0.14,0.18-0.2,0.26c-0.01,0.01-0.02,0.03-0.03,0.04c-0.13,0.23-0.23,0.49-0.31,0.75
              c-0.02,0.07-0.04,0.13-0.06,0.19c0.08-0.2,0.17-0.4,0.25-0.6c-0.02,0.05-0.05,0.1-0.08,0.15c-0.18,0.29-0.28,0.6-0.3,0.93
              c-0.07,0.33-0.06,0.66,0.04,1c0.08,0.33,0.23,0.61,0.45,0.85c0.18,0.27,0.41,0.49,0.7,0.64c0.2,0.08,0.4,0.17,0.6,0.25
              c0.44,0.12,0.89,0.12,1.33,0c0.2-0.08,0.4-0.17,0.6-0.25c0.38-0.22,0.67-0.52,0.9-0.9c0.16-0.25,0.27-0.55,0.36-0.83
              c0.02-0.07,0.04-0.14,0.07-0.21c-0.08,0.2-0.17,0.4-0.25,0.6c0.02-0.04,0.04-0.07,0.06-0.1c-0.13,0.17-0.26,0.34-0.39,0.51
              c0.02-0.03,0.05-0.05,0.08-0.08c-0.17,0.13-0.34,0.26-0.51,0.39c0.06-0.05,0.13-0.08,0.2-0.11c-0.2,0.08-0.4,0.17-0.6,0.25
              c0.01,0,0.02-0.01,0.02-0.01c-0.81-0.21-1.62-0.43-2.43-0.64c0.12,0.12,0.23,0.24,0.33,0.37c-0.13-0.17-0.26-0.34-0.39-0.51
              c0.09,0.12,0.17,0.25,0.23,0.39c-0.08-0.2-0.17-0.4-0.25-0.6c0.1,0.25,0.15,0.51,0.19,0.77c0.58-1.03,1.16-2.05,1.75-3.08
              c-0.17,0.04-0.33,0.09-0.5,0.11c0.22-0.03,0.44-0.06,0.66-0.09c-0.06,0.01-0.13,0.01-0.19,0c0.22,0.03,0.44,0.06,0.66,0.09
              c-0.04-0.01-0.08-0.02-0.12-0.03c0.2,0.08,0.4,0.17,0.6,0.25c-0.08-0.04-0.16-0.09-0.24-0.14c-0.13-0.08-0.26-0.16-0.39-0.23
              c0.41,0.72,0.83,1.44,1.24,2.16c0-0.06,0.01-0.12,0.01-0.17c-0.03,0.22-0.06,0.44-0.09,0.66c0.02-0.14,0.06-0.28,0.11-0.42
              c-0.08,0.2-0.17,0.4-0.25,0.6c0.06-0.13,0.13-0.25,0.21-0.36c-0.13,0.17-0.26,0.34-0.39,0.51c0.03-0.03,0.05-0.07,0.08-0.1
              c0.24-0.23,0.42-0.49,0.52-0.8c0.16-0.3,0.23-0.62,0.22-0.96c0.01-0.34-0.06-0.67-0.22-0.96c-0.1-0.31-0.27-0.58-0.52-0.8
              c-0.17-0.13-0.34-0.26-0.51-0.39c-0.39-0.23-0.81-0.34-1.26-0.34c-0.22,0.03-0.44,0.06-0.66,0.09
              C441.48,258.8,441.11,259.01,440.8,259.32L440.8,259.32z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M452.53,277.13c0.37,3.85,1.48,7.62,1.57,11.49c1.64-0.22,3.27-0.44,4.91-0.66c-0.02-0.16-0.05-0.31-0.07-0.47
              c-0.1-0.68,0.07,0.62,0-0.03c-0.06-0.49-0.12-0.99-0.17-1.48c-0.12-1.2-0.22-2.4-0.27-3.6c-0.07-1.44-0.09-2.88-0.03-4.32
              c0.03-0.66,0.07-1.32,0.14-1.97c0.02-0.2,0.16-1.13,0.05-0.48c0.06-0.37,0.12-0.74,0.19-1.1c0.13-0.63,0.28-1.26,0.49-1.87
              c0.07-0.2,0.45-0.97,0.1-0.31c0.14-0.27,0.26-0.55,0.42-0.81c0.13-0.21,0.63-0.71,0.11-0.19c0.15-0.14,0.35-0.32,0.47-0.49
              c0.25-0.35-0.48,0.22-0.09,0.07c0.07-0.03,0.14-0.12,0.22-0.14c0.17-0.05,0.61-0.16-0.12,0.02c0.11-0.03,0.26-0.13,0.38-0.12
              c0.11,0.01,0.78-0.05-0.12-0.01c0.12,0,0.24-0.03,0.36-0.02c0.12,0.01,0.26,0.02,0.38,0.01c0.51-0.05-0.53-0.17-0.02-0.01
              c0.11,0.03,0.25,0.06,0.36,0.08c0.2,0.04,0.58,0.32,0.12,0.03c0.1,0.07,0.23,0.12,0.33,0.19c0.23,0.16,0.21,0.15-0.05-0.04
              c0.1,0.09,0.19,0.18,0.28,0.27c0.09,0.1,0.18,0.2,0.27,0.3c-0.38-0.44,0.04,0.09,0.1,0.17c0.2,0.31,0.36,0.64,0.53,0.96
              c0.34,0.64-0.15-0.45,0.11,0.24c0.05,0.15,0.11,0.29,0.16,0.44c0.16,0.45,0.3,0.91,0.43,1.37c0.47,1.7,0.73,3.44,1.1,5.16
              c0.56,2.65,1.77,5.21,1.81,7.95c0.05,3.21,5.05,3.22,5,0c-0.05-3.09-1.22-5.9-1.9-8.86c-0.38-1.66-0.62-3.34-1.04-4.99
              c-1-3.96-3.11-8.16-7.75-8.26c-7.37-0.16-7.96,9.79-7.87,15.03c0.05,2.89,0.32,5.79,0.76,8.65c0.19,1.21,1.64,1.96,2.74,1.79
              c1.19-0.18,2.2-1.19,2.17-2.46c-0.09-3.89-1.2-7.64-1.57-11.49c-0.13-1.34-1.06-2.5-2.5-2.5
              C453.78,274.63,452.4,275.78,452.53,277.13L452.53,277.13z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M483.01,269.67c-1.32-2.62-3.37-5.49-6.45-6.08c-3.08-0.59-6.11,1.13-7.61,3.79c-2.39,4.26-2.76,10.54-0.66,14.96
              c1.03,2.16,3.16,3.8,5.59,3.93c2.36,0.13,4.21-1.32,5.54-3.13c2.29-3.12,3.29-7.4,3.84-11.17c0.49-3.37,0.64-6.82,0.68-10.23
              c0.06-4.27-0.18-8.58-1.1-12.77c-1.61,0.44-3.21,0.89-4.82,1.33c1.51,5.45,1.14,11.18,1.48,16.77c0.17,2.73,0.54,5.45,0.84,8.16
              c0.31,2.88,0.68,5.78,1.72,8.5c0.48,1.26,1.68,2.13,3.08,1.75c1.21-0.33,2.23-1.81,1.75-3.08c-0.99-2.58-1.35-5.29-1.63-8.02
              c-0.28-2.72-0.66-5.44-0.79-8.17c-0.29-5.75-0.05-11.63-1.61-17.24c-0.87-3.11-5.51-1.8-4.82,1.33
              c1.29,5.85,1.06,12.02,0.61,17.96c-0.07,0.92-0.15,1.84-0.25,2.75c0.09-0.88-0.02,0.08-0.05,0.26c-0.06,0.37-0.13,0.75-0.2,1.12
              c-0.33,1.67-0.75,3.32-1.33,4.92c-0.06,0.17-0.44,1.11-0.28,0.75c-0.15,0.36-0.32,0.71-0.5,1.06c-0.17,0.33-0.36,0.65-0.56,0.96
              c-0.11,0.18-0.23,0.35-0.36,0.52c0.34-0.44-0.03,0.02-0.12,0.11c-0.14,0.13-0.27,0.27-0.41,0.4c0.4-0.37,0.14-0.11,0-0.01
              c-0.12,0.08-0.25,0.15-0.38,0.21c0.71-0.37,0.04,0.05-0.04-0.02c-0.28-0.24,0.01,0.27,0.16-0.02c-0.05,0.09-0.85-0.12-0.04,0.04
              c-0.15-0.03-0.32-0.06-0.46-0.12c-0.17-0.06-0.13-0.04,0.12,0.06c-0.13-0.06-0.27-0.13-0.39-0.21c0.01,0-0.56-0.42-0.26-0.16
              c0.29,0.25-0.18-0.21-0.19-0.22c-0.42-0.53,0.24,0.43-0.12-0.17c-0.07-0.13-0.15-0.26-0.22-0.39c-0.03-0.04-0.31-0.72-0.13-0.26
              c-0.07-0.19-0.14-0.39-0.2-0.58c-0.15-0.5-0.26-1.01-0.34-1.52c-0.08-0.5,0.05,0.59-0.03-0.28c-0.02-0.28-0.04-0.56-0.06-0.84
              c-0.02-0.47-0.02-0.95,0-1.42c0.04-1.11,0.1-1.63,0.34-2.7c0.12-0.52,0.26-1.04,0.44-1.54c0.18-0.53,0.11-0.39,0.43-0.98
              c0.11-0.2,0.22-0.39,0.35-0.58c0.35-0.55-0.31,0.29,0.12-0.15c0.14-0.14,0.27-0.28,0.42-0.4c-0.57,0.49,0.33-0.18,0.5-0.25
              c0.84-0.34,1.27-0.17,1.9,0.37c0.99,0.84,1.63,2.06,2.21,3.2C480.15,275.07,484.46,272.54,483.01,269.67L483.01,269.67z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M489.85,270.9c3.15-2.9,7.68-8.42,4.81-12.87c-1.49-2.32-4.37-3.51-6.91-2.14c-1.88,1.02-3.09,3.09-3.92,4.99
              c-1.77,4.08-2.36,8.87-0.86,13.14c0.85,2.43,2.29,4.52,4.44,5.98c2.29,1.55,5.26,2.06,7.95,1.56c5.78-1.07,8.96-7.33,10.11-12.54
              c0.29-1.32-0.4-2.7-1.75-3.08c-1.25-0.34-2.78,0.42-3.08,1.75c-0.44,2.01-0.93,3.45-1.84,5.07c-0.21,0.37-0.43,0.73-0.67,1.09
              c0,0-0.49,0.66-0.21,0.31c-0.13,0.16-0.26,0.32-0.4,0.48c-0.75,0.85-0.98,1.06-1.82,1.53c-0.21,0.12-0.42,0.23-0.64,0.32
              c0.4-0.17-0.09,0.02-0.19,0.04c-0.2,0.05-0.4,0.1-0.59,0.16c-0.67,0.19,0.39,0.02-0.33,0.05c-1.13,0.05-1.54-0.02-2.64-0.35
              c-0.09-0.03-0.6-0.23-0.22-0.07c-0.22-0.1-0.44-0.21-0.65-0.33c-0.21-0.12-0.4-0.25-0.6-0.38c-0.07-0.05-0.35-0.3-0.05-0.03
              c-0.35-0.3-0.67-0.63-0.97-0.98c0.07,0.08,0.24,0.36-0.03-0.04c-0.17-0.25-0.34-0.51-0.49-0.78c-0.11-0.2-0.21-0.41-0.32-0.62
              c-0.04-0.07-0.19-0.44-0.03-0.04c-0.18-0.44-0.32-0.89-0.44-1.35c-0.1-0.39-0.18-0.78-0.25-1.17c-0.15-0.76,0.02,0.48-0.04-0.3
              c-0.02-0.24-0.04-0.49-0.06-0.73c-0.05-0.9,0-1.78,0.07-2.68c0.06-0.78-0.1,0.47,0.04-0.29c0.03-0.16,0.05-0.31,0.08-0.47
              c0.07-0.39,0.16-0.78,0.27-1.16c0.17-0.61,0.39-1.2,0.62-1.79c0.25-0.65-0.23,0.45,0.08-0.19c0.1-0.2,0.18-0.39,0.28-0.59
              c0.2-0.39,0.41-0.77,0.64-1.13c0.11-0.18,0.23-0.35,0.36-0.52c-0.3,0.4-0.09,0.1-0.01,0.03c0.13-0.12,0.29-0.28,0.4-0.41
              c0.38-0.49-0.3,0.41,0.06-0.05c0.06-0.07,0.21-0.12,0.3-0.17c-0.62,0.38-0.07,0.1,0.02,0.04c-0.23,0.15-0.29,0.12-0.52,0.01
              c0.26,0.08,0.22,0.05-0.09-0.08c0.06,0.03,0.11,0.06,0.17,0.09c0.32,0.48-0.28-0.36,0.19,0.12c0.12,0.11,0.23,0.22,0.34,0.34
              c-0.16-0.21-0.16-0.2,0,0.04c0.1,0.11,0.12,0.42,0-0.02c0.04,0.13,0.08,0.27,0.1,0.4c-0.04-0.32-0.05-0.33-0.03-0.04
              c-0.01,0.1-0.12,0.77,0,0.2c-0.05,0.22-0.1,0.45-0.17,0.67c-0.01,0.03-0.29,0.75-0.09,0.3c-0.1,0.23-0.22,0.45-0.33,0.67
              c-0.98,1.81-2.03,2.97-3.62,4.43C483.94,269.54,487.49,273.07,489.85,270.9L489.85,270.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M499.1,264.63c1.52,4.27,2.4,8.73,3.79,13.04c0.41,1.29,1.73,2.11,3.08,1.75c1.42-0.39,1.96-1.74,1.75-3.08
              c-0.66-4.04-1.5-7.98-1.12-12.1c0.13-1.42,0.67-3.26,1.47-4.6c1.05-1.74,2.7-3.06,4.52-4.1c2.79-1.6,0.28-5.92-2.52-4.32
              c-4.49,2.57-7.6,6.6-8.32,11.77c-0.69,4.91,0.36,9.84,1.14,14.68c1.61-0.44,3.21-0.89,4.82-1.33c-1.39-4.31-2.27-8.77-3.79-13.04
              c-0.45-1.27-1.7-2.12-3.08-1.75C499.62,261.89,498.65,263.35,499.1,264.63L499.1,264.63z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M509.95,247.58c1.64,5.29,3.3,10.58,4.71,15.93c0.69,2.62,1.33,5.26,1.85,7.92c0.52,2.62,0.96,5.25,1.87,7.78
              c1.1,3.05,5.39,1.84,4.82-1.33c-0.77-4.32-2.46-8.59-2.2-13.03c0.1-1.63,0.61-3.05,1.95-3.82c1.34-0.77,3.18-0.85,4.49-0.19
              c0.55,0.27,0.97,0.65,1.42,1.2c-0.3-0.36,0.27,0.44,0.36,0.58c0.07,0.13,0.14,0.26,0.21,0.39c0.33,0.62-0.14-0.44,0.1,0.22
              c0.24,0.65,0.41,1.32,0.52,2c-0.08-0.48,0.05,0.57,0.06,0.75c0.02,0.44,0.02,0.89,0,1.33c-0.02,0.37-0.04,0.73-0.08,1.1
              c-0.08,0.78,0.05-0.27-0.03,0.19c-0.03,0.2-0.07,0.4-0.11,0.61c-0.16,0.8-0.37,1.58-0.64,2.35c-0.05,0.15-0.11,0.3-0.16,0.45
              c0.01-0.04-0.28,0.68-0.14,0.36c0.04-0.1-0.28,0.57-0.37,0.75c-0.17,0.33-0.36,0.65-0.56,0.96c-0.1,0.16-0.68,0.93-0.35,0.53
              c-0.23,0.28-0.47,0.55-0.74,0.81c-0.11,0.11-0.23,0.21-0.35,0.32c-0.45,0.39,0.42-0.25-0.08,0.04c-0.1,0.06-0.2,0.12-0.3,0.18
              c-0.46,0.31-0.06,0.06-0.09,0.07c-0.27,0.08-1.06,0.14-0.37,0.14c-0.1,0-0.22,0.03-0.32,0.01c-0.52-0.09,0.48,0.19,0.01,0.01
              c-0.13-0.05-0.28-0.08-0.41-0.11c-0.17-0.06-0.11-0.03,0.18,0.09c-0.11-0.05-0.22-0.11-0.33-0.17c-0.11-0.07-0.22-0.14-0.33-0.21
              c-0.53-0.34,0.33,0.33-0.16-0.13c-0.36-0.34-0.68-0.69-0.99-1.07c-0.85-1.05-2.65-0.88-3.54,0c-1.04,1.04-0.85,2.48,0,3.54
              c2.69,3.36,6.93,4,10.25,1.15c2.99-2.58,4.43-6.8,4.88-10.61c0.46-3.93-0.39-8.43-3.54-11.1c-4.36-3.7-12.05-2.48-14.46,2.91
              c-2.66,5.96,0.31,12.76,1.38,18.74c1.61-0.44,3.21-0.89,4.82-1.33c-0.88-2.45-1.32-5-1.82-7.55c-0.54-2.74-1.19-5.45-1.9-8.15
              c-1.41-5.36-3.07-10.64-4.71-15.93C513.82,243.19,508.99,244.5,509.95,247.58L509.95,247.58z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M546.47,264.28c-0.37-2.28-1.23-4.5-2.68-6.31c-1.71-2.14-4.3-2.99-6.96-2.48c-4.52,0.86-6.15,6.01-6.47,9.99
              c-0.34,4.27,0.77,8.79,4.61,11.18c1.87,1.17,4.34,1.62,6.44,0.88c2.42-0.84,4.13-2.93,4.65-5.43c0.5-2.41,0.4-4.93,0.08-7.35
              c-0.35-2.58-1.04-5.34-2.9-7.27c-0.91-0.94-2.63-0.98-3.54,0c-0.93,1.01-0.97,2.53,0,3.54c0.06,0.06,0.12,0.15,0.18,0.2
              c0.19,0.15-0.01,0.26-0.13-0.18c0.04,0.13,0.21,0.31,0.28,0.43c0.09,0.15,0.18,0.31,0.26,0.47c0.05,0.1,0.09,0.19,0.14,0.29
              c0.2,0.41-0.12-0.37-0.03-0.07c0.26,0.89,0.55,1.75,0.72,2.66c0.04,0.21,0.07,0.43,0.11,0.64c0.07,0.35-0.04-0.47-0.01-0.11
              c0.01,0.09,0.02,0.19,0.03,0.28c0.06,0.49,0.1,0.99,0.12,1.48c0.05,0.88,0.05,1.77-0.01,2.65c-0.01,0.18-0.03,0.35-0.04,0.53
              c-0.01,0.09-0.03,0.19-0.03,0.28c0-0.02,0.11-0.66,0.04-0.26c-0.08,0.43-0.19,0.83-0.29,1.25c-0.11,0.43,0.23-0.39-0.04,0.1
              c-0.09,0.16-0.17,0.33-0.27,0.48c-0.36,0.55,0.21-0.17-0.07,0.09c-0.1,0.1-0.36,0.27-0.41,0.4c0.13-0.3,0.41-0.27,0.17-0.14
              c-0.08,0.04-0.16,0.1-0.23,0.15c-0.1,0.06-0.59,0.29-0.2,0.12c0.41-0.18-0.11,0.03-0.22,0.05c-0.09,0.02-0.18,0.04-0.27,0.06
              c-0.44,0.14,0.44-0.01,0.19-0.02c-0.33-0.01-0.73-0.04-1.06-0.01c0.05,0,0.63,0.13,0.24,0.02c-0.07-0.02-0.14-0.03-0.21-0.05
              c-0.22-0.05-0.43-0.13-0.64-0.2c-0.41-0.13,0.45,0.25-0.03-0.03c-0.16-0.09-0.32-0.17-0.48-0.27c-0.09-0.06-0.18-0.12-0.27-0.18
              c-0.4-0.25,0.13,0.1,0.12,0.1c-0.08,0.02-0.42-0.37-0.5-0.44c-0.16-0.16-0.31-0.33-0.46-0.5c-0.18-0.2-0.17-0.04,0.07,0.09
              c-0.05-0.03-0.12-0.17-0.15-0.22c-0.18-0.29-0.33-0.58-0.49-0.88c-0.14-0.26,0.2,0.55,0.11,0.26c-0.02-0.08-0.06-0.16-0.09-0.24
              c-0.05-0.14-0.1-0.29-0.14-0.43c-0.1-0.35-0.18-0.72-0.25-1.08c-0.02-0.12-0.1-0.68-0.05-0.25c0.05,0.47-0.02-0.25-0.03-0.34
              c-0.04-0.41-0.06-0.83-0.06-1.24c0-0.4,0-0.8,0.03-1.2c0.01-0.19,0.03-0.37,0.04-0.56c0.01-0.14,0.05-0.79,0.04-0.39
              c-0.02,0.43,0.05-0.28,0.07-0.38c0.03-0.18,0.07-0.36,0.11-0.54c0.09-0.4,0.2-0.8,0.33-1.19c0.05-0.15,0.21-0.41,0.21-0.57
              c0-0.01-0.22,0.49-0.11,0.26c0.04-0.09,0.08-0.18,0.12-0.26c0.16-0.34,0.36-0.66,0.56-0.98c0.28-0.45-0.31,0.32-0.03,0.02
              c0.12-0.13,0.23-0.26,0.35-0.39c0.08-0.08,0.5-0.41,0.13-0.14c-0.37,0.27,0.16-0.1,0.27-0.16c0.05-0.03,0.11-0.06,0.17-0.09
              c0.38-0.22-0.09,0.1-0.19,0.07c0.12,0.03,0.53-0.16,0.65-0.18c0.05-0.01,0.24-0.02,0.28-0.05c-0.2,0.19-0.41,0.02-0.04,0.03
              c0.11,0,0.23,0,0.34,0c0.55,0.01-0.07,0.06-0.09-0.02c0-0.01,0.59,0.18,0.61,0.17c-0.08,0.08-0.49-0.25-0.17-0.07
              c0.11,0.06,0.23,0.12,0.34,0.19c0.09,0.05,0.17,0.12,0.26,0.17c0.24,0.14-0.43-0.41-0.08-0.05c0.15,0.15,0.31,0.29,0.45,0.45
              c-0.02-0.02,0.21,0.24,0.21,0.24c0,0-0.35-0.51-0.05-0.05c0.13,0.21,0.27,0.42,0.39,0.63c0.11,0.2,0.21,0.4,0.31,0.6
              c0.03,0.07,0.14,0.24,0.14,0.31c0-0.2-0.16-0.43-0.03-0.05c0.32,0.91,0.57,1.83,0.73,2.79c0.21,1.29,1.89,2.13,3.08,1.75
              C546.13,266.9,546.7,265.67,546.47,264.28L546.47,264.28z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M548.42,265.56c4.19-2.21,9.81-6.32,8.35-11.8c-0.72-2.71-3.22-4.42-6-4.18c-2.56,0.23-4.94,1.53-6.41,3.65
              c-3.21,4.63-1.99,11.19,0.74,15.72c2.59,4.29,8.04,8.65,13.37,6.83c5.61-1.92,7.48-8.64,7.98-13.93c0.13-1.35-1.24-2.5-2.5-2.5
              c-1.46,0-2.37,1.15-2.5,2.5c-0.02,0.22-0.05,0.45-0.07,0.67c-0.05,0.49,0.05-0.25-0.03,0.24c-0.09,0.53-0.18,1.05-0.3,1.57
              c-0.22,0.96-0.52,1.88-0.84,2.81c-0.16,0.45,0.12-0.22-0.08,0.21c-0.09,0.19-0.18,0.39-0.28,0.58c-0.22,0.43-0.46,0.86-0.73,1.26
              c-0.11,0.17-0.23,0.33-0.35,0.49c0.26-0.34,0.03-0.06-0.1,0.1c-0.13,0.14-0.26,0.28-0.4,0.41c-0.08,0.08-0.56,0.49-0.31,0.29
              c0.26-0.21-0.28,0.18-0.35,0.22c-0.15,0.09-0.33,0.16-0.48,0.26c0.33-0.21,0.2-0.07-0.08,0.02c-0.2,0.07-0.43,0.09-0.62,0.16
              c-0.25,0.09,0.16-0.02,0.2-0.02c-0.1,0-0.19,0.02-0.29,0.02c-0.23,0-0.46,0-0.69,0c-0.46,0,0.31,0.1-0.16-0.02
              c-0.25-0.06-0.51-0.11-0.76-0.19c-0.23-0.07-0.45-0.15-0.68-0.23c-0.26-0.1-0.03-0.02,0.02,0.01c-0.12-0.06-0.24-0.11-0.36-0.17
              c-0.51-0.26-1-0.55-1.47-0.88c-0.09-0.06-0.17-0.12-0.26-0.19c0.03,0.03,0.29,0.24,0.08,0.05c-0.17-0.15-0.34-0.29-0.5-0.44
              c-0.39-0.36-0.75-0.75-1.1-1.15c-0.08-0.09-0.15-0.18-0.22-0.27c0.03,0.04,0.2,0.27,0.03,0.04c-0.14-0.19-0.28-0.38-0.41-0.58
              c-0.29-0.43-0.56-0.87-0.81-1.33c-0.14-0.25-0.26-0.5-0.39-0.75c-0.05-0.11-0.28-0.62-0.22-0.48c0.06,0.14-0.14-0.38-0.19-0.49
              c-0.1-0.27-0.19-0.54-0.27-0.81c-0.15-0.5-0.28-1.01-0.38-1.52c-0.02-0.11-0.11-0.7-0.09-0.49c0.03,0.27-0.01-0.12-0.01-0.14
              c-0.01-0.16-0.03-0.31-0.04-0.47c-0.03-0.51-0.03-1.01,0.01-1.52c0.01-0.11,0.08-0.7,0.04-0.47c-0.05,0.29,0.02-0.09,0.03-0.12
              c0.04-0.15,0.06-0.3,0.1-0.45c0.11-0.44,0.24-0.88,0.41-1.3c-0.17,0.41,0.12-0.21,0.19-0.33c0.13-0.23,0.31-0.45,0.42-0.69
              c-0.16,0.34-0.19,0.2,0.04-0.03c0.13-0.13,0.26-0.25,0.39-0.37c0.32-0.31-0.31,0.17,0.09-0.06c0.22-0.13,0.43-0.27,0.66-0.39
              c0.21-0.11,0.51-0.19,0.11-0.06c0.18-0.06,0.36-0.13,0.55-0.18c0.18-0.05,0.37-0.07,0.55-0.13c-0.46,0.13-0.12,0.01,0.16,0.01
              c0.12,0,0.25,0.03,0.36,0.01c0.25-0.04-0.18-0.01-0.23-0.03c0.13,0.06,0.35,0.11,0.5,0.14c-0.4-0.07-0.22-0.16,0.01,0.05
              c-0.51-0.48,0.16,0.22-0.07-0.05c-0.2-0.25-0.01-0.01,0.02,0.05c0.19,0.43-0.05-0.27,0.04,0.05c0.06,0.19,0.18,0.78,0.12,0.27
              c0.03,0.24-0.01,0.48,0.01,0.71c0.03,0.27,0.01-0.11,0.03-0.16c-0.04,0.13-0.06,0.26-0.09,0.39c-0.03,0.1-0.07,0.19-0.09,0.29
              c-0.1,0.34,0.18-0.34,0.01-0.03c-0.13,0.25-0.27,0.49-0.42,0.74c-0.06,0.09-0.12,0.18-0.18,0.27c0.14-0.2,0.13-0.15-0.01,0.02
              c-0.17,0.2-0.35,0.4-0.53,0.59c-0.4,0.42-0.84,0.79-1.28,1.17c-0.02,0.01-0.31,0.25-0.11,0.09c0.19-0.15-0.17,0.12-0.21,0.15
              c-0.21,0.15-0.43,0.31-0.65,0.45c-0.82,0.55-1.68,1.05-2.56,1.52c-1.19,0.63-1.56,2.29-0.9,3.42
              C545.71,265.87,547.22,266.19,548.42,265.56L548.42,265.56z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M560.93,246.1c0.42,4.91,1.07,9.81,2.1,14.64c0.39,1.86,0.76,3.7,1.1,5.57c0.36,1.96,0.95,3.8,2.26,5.34
              c0.75,0.88,2.09,0.85,3.03,0.39c3.02-1.48,5.08-4.6,6.71-7.42c1.65-2.85,2.95-5.88,4.64-8.71c-1.52-0.64-3.05-1.28-4.57-1.93
              c-1.41,4.65-2.57,10.78,1.48,14.51c3.81,3.51,10.13,3.24,14.13,0.17c1.07-0.82,1.64-2.15,0.9-3.42c-0.62-1.05-2.34-1.72-3.42-0.9
              c-2.27,1.74-6.08,2.66-8.32,0.38c-2.26-2.31-0.75-6.78,0.05-9.41c0.83-2.74-3.13-4.34-4.57-1.93c-1.56,2.63-2.79,5.44-4.29,8.11
              c-1.34,2.39-2.95,5.1-5.26,6.23c1.01,0.13,2.02,0.26,3.03,0.39c-0.67-0.78-0.89-2.63-1.09-3.83c-0.3-1.79-0.71-3.55-1.08-5.33
              c-0.88-4.24-1.46-8.53-1.82-12.85c-0.11-1.35-1.07-2.5-2.5-2.5C562.16,243.6,560.81,244.74,560.93,246.1L560.93,246.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M635.48,262.14c0.17-5.06-1.88-12.07-7.83-12.59c-3.09-0.27-5.97,1.58-7.59,4.11c-1.46,2.3-2,5.21-1.95,7.9
              c0.05,2.72,0.71,5.4,2.14,7.73c1.18,1.91,2.97,3.68,5.27,4.07c4.4,0.76,8.08-2.75,9.74-6.51c1.08-2.47,1.53-5.08,1.08-7.75
              c-0.48-2.78-2.19-5.09-4.5-6.64c-1.09-0.73-2.82-0.24-3.42,0.9c-0.67,1.26-0.26,2.64,0.9,3.42c0.11,0.07,0.23,0.19,0.35,0.24
              c-0.44-0.2-0.34-0.29-0.18-0.14c0.17,0.15,0.34,0.29,0.5,0.45c0.16,0.15,0.3,0.31,0.45,0.47c0.06,0.06,0.32,0.41,0.05,0.04
              c-0.29-0.38,0.05,0.08,0.1,0.15c0.23,0.34,0.41,0.71,0.6,1.07c0.14,0.27-0.16-0.02-0.11-0.26c-0.01,0.07,0.1,0.27,0.12,0.34
              c0.06,0.19,0.12,0.38,0.17,0.58c0.05,0.19,0.09,0.39,0.13,0.59c0.14,0.64-0.04-0.59,0.01,0.05c0.03,0.44,0.05,0.88,0.03,1.33
              c-0.01,0.2-0.04,0.4-0.05,0.61c-0.02,0.39,0.04-0.29,0.04-0.3c-0.04,0.1-0.04,0.26-0.06,0.36c-0.07,0.4-0.17,0.8-0.28,1.19
              c-0.09,0.32-0.4,0.83-0.4,1.15c0-0.4,0.22-0.49,0.06-0.15c-0.03,0.07-0.07,0.15-0.1,0.22c-0.1,0.22-0.21,0.43-0.33,0.64
              c-0.19,0.34-0.4,0.68-0.63,1c-0.04,0.06-0.09,0.13-0.14,0.19c-0.18,0.25-0.32,0.18,0.11-0.14c-0.15,0.11-0.27,0.33-0.4,0.48
              c-0.23,0.26-0.48,0.51-0.74,0.74c-0.25,0.22-0.63,0.34,0.01,0.01c-0.18,0.09-0.34,0.24-0.51,0.35c-0.14,0.09-0.29,0.17-0.44,0.25
              c-0.06,0.03-0.12,0.06-0.18,0.09c-0.37,0.2,0.64-0.23,0.24-0.1c-0.32,0.1-0.64,0.17-0.96,0.26c0.14-0.04,0.67-0.03,0.16-0.04
              c-0.15,0-0.31-0.02-0.46-0.01c-0.41,0.03,0.69,0.14,0.29,0.04c-0.06-0.02-0.12-0.02-0.19-0.04c-0.19-0.04-0.37-0.11-0.55-0.17
              c-0.23-0.07,0.64,0.32,0.05,0c-0.16-0.09-0.3-0.19-0.45-0.28c-0.3-0.17,0.23,0.17,0.24,0.19c-0.04-0.08-0.2-0.16-0.26-0.22
              c-0.17-0.15-0.32-0.31-0.47-0.48c-0.07-0.08-0.14-0.16-0.21-0.24c-0.32-0.35,0.27,0.41,0.1,0.13c-0.11-0.18-0.25-0.35-0.36-0.53
              c-0.32-0.5-0.58-1.03-0.84-1.56c-0.26-0.53,0.14,0.4,0.04,0.09c-0.03-0.1-0.08-0.2-0.12-0.31c-0.08-0.21-0.15-0.43-0.21-0.65
              c-0.14-0.49-0.24-0.99-0.32-1.49c-0.04-0.25,0.06-0.43,0.02,0.14c0.01-0.12-0.03-0.26-0.04-0.38c-0.02-0.23-0.04-0.47-0.05-0.7
              c-0.03-0.58-0.02-1.17,0.02-1.76c0.01-0.2,0.13-0.62,0.07-0.8c0.11,0.37-0.07,0.45-0.01,0.11c0.02-0.13,0.04-0.26,0.07-0.39
              c0.1-0.54,0.24-1.08,0.42-1.61c0.04-0.12,0.09-0.24,0.13-0.37c0.17-0.5-0.21,0.41-0.05,0.1c0.11-0.2,0.19-0.41,0.3-0.61
              c0.12-0.22,0.25-0.43,0.38-0.64c0.08-0.11,0.2-0.24,0.26-0.36c-0.22,0.48-0.21,0.26-0.02,0.05c0.16-0.18,0.33-0.35,0.51-0.51
              c0.51-0.45-0.39,0.24,0.19-0.14c0.21-0.13,0.42-0.24,0.64-0.36c0.38-0.2-0.14,0.09-0.18,0.07c0.09,0.03,0.41-0.14,0.51-0.17
              c0.13-0.04,0.26-0.07,0.4-0.1c0.59-0.12,0.08,0.08-0.01-0.01c0.04,0.03,0.79,0.03,0.9,0c-0.44,0.1-0.49-0.1-0.18-0.01
              c0.15,0.04,0.31,0.1,0.46,0.13c0.43,0.09-0.26-0.07-0.22-0.1c0.03-0.03,0.37,0.19,0.41,0.22c0.05,0.03,0.24,0.19,0.28,0.18
              c-0.33-0.26-0.39-0.31-0.19-0.14c0.03,0.03,0.07,0.06,0.1,0.09c0.2,0.19,0.38,0.4,0.57,0.59c0.34,0.34-0.24-0.4-0.09-0.12
              c0.08,0.14,0.19,0.28,0.28,0.42c0.14,0.23,0.27,0.46,0.39,0.7c0.06,0.11,0.11,0.22,0.16,0.34c0.22,0.46-0.18-0.49-0.02-0.04
              c0.1,0.29,0.21,0.57,0.3,0.87c0.17,0.56,0.31,1.14,0.41,1.72c0.02,0.12,0.04,0.25,0.06,0.37c0.07,0.43-0.02,0.22-0.01-0.07
              c-0.01,0.28,0.06,0.58,0.08,0.86c0.04,0.53,0.05,1.06,0.03,1.59c-0.04,1.31,1.18,2.56,2.5,2.5
              C634.37,264.58,635.43,263.54,635.48,262.14L635.48,262.14z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M629.97,256.19c0.71,2.39,1.79,4.71,3,6.89c1.32,2.37,2.92,4.81,5.04,6.55c2.28,1.87,5.12,2.81,7.89,1.35
              c1.42-0.75,2.51-2.1,3.33-3.44c1.21-1.98,1.92-4.18,2.08-6.49c0.33-4.84-2.24-9.12-3.28-13.7c-0.3-1.32-1.82-2.09-3.08-1.75
              c-1.35,0.37-2.05,1.75-1.75,3.08c0.42,1.83,1.08,3.58,1.7,5.34c0.28,0.81,0.56,1.61,0.8,2.43c0.12,0.43,0.24,0.86,0.34,1.29
              c0.05,0.22,0.1,0.43,0.14,0.65c0,0,0.13,0.8,0.07,0.39c0.24,1.87,0.11,3.41-0.68,5.02c-0.29,0.6-0.62,1.19-1,1.74
              c-0.07,0.1-0.48,0.58-0.06,0.1c-0.08,0.09-0.15,0.18-0.23,0.27c-0.12,0.13-0.5,0.64-0.7,0.67c0.26-0.04,0.42-0.27,0.09-0.09
              c-0.1,0.05-0.2,0.12-0.3,0.18c-0.55,0.32,0.48-0.08-0.12,0.04c-0.07,0.01-0.17,0.03-0.24,0.06c-0.43,0.15,0.63,0.01,0.18-0.03
              c-0.07-0.01-0.17,0-0.24,0c-0.55,0.04,0.2,0.07,0.12,0.04c-0.16-0.07-0.37-0.09-0.54-0.13c-0.61-0.15,0.42,0.26-0.12-0.04
              c-0.2-0.11-0.4-0.22-0.6-0.34c-0.12-0.08-0.23-0.17-0.36-0.24c-0.39-0.24,0.4,0.37,0.04,0.03c-0.39-0.36-0.78-0.7-1.15-1.09
              c-0.19-0.2-0.38-0.41-0.56-0.63c-0.15-0.17-0.63-0.8-0.26-0.3c-0.75-1.01-1.44-2.06-2.07-3.16c-0.66-1.15-1.24-2.34-1.78-3.55
              c-0.04-0.1-0.08-0.19-0.13-0.29c0.29,0.65,0.08,0.19,0.02,0.03c-0.11-0.27-0.21-0.53-0.31-0.8c-0.17-0.46-0.33-0.92-0.47-1.39
              c-0.38-1.29-1.75-2.11-3.08-1.75C630.43,253.46,629.58,254.89,629.97,256.19L629.97,256.19z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M653.8,257.68c3.81-1.66,8.17-7,5.32-11.17c-0.98-1.44-2.44-2.48-4.2-2.62c-1.47-0.12-2.96,0.68-3.91,1.76
              c-1.56,1.76-2.23,4.06-2.44,6.36c-0.31,3.46,0.88,7.15,2.92,9.93c2,2.72,4.9,5.08,8.42,5.18c4.71,0.13,7.44-4.21,8.59-8.23
              c0.36-1.26-0.44-2.78-1.75-3.08c-1.33-0.3-2.69,0.4-3.08,1.75c-0.12,0.42-0.25,0.84-0.4,1.24c-0.09,0.24-0.26,0.64-0.05,0.18
              c-0.09,0.21-0.19,0.42-0.29,0.63c-0.16,0.31-0.32,0.62-0.51,0.91c-0.09,0.15-0.19,0.29-0.29,0.43c-0.23,0.34,0.36-0.4,0-0.01
              c-0.19,0.21-0.39,0.41-0.6,0.61c-0.03,0.03-0.17,0.12-0.19,0.16c-0.01,0.01,0.46-0.33,0.27-0.2c-0.13,0.08-0.26,0.18-0.39,0.26
              c-0.11,0.07-0.23,0.13-0.35,0.19c-0.38,0.21,0.01-0.09,0.19-0.07c-0.19-0.02-0.54,0.16-0.73,0.21c-0.5,0.12,0.26-0.02,0.19-0.02
              c-0.12,0-0.25,0.02-0.38,0.02c-0.11,0-0.22,0-0.32-0.01c-0.07,0-0.15-0.01-0.22-0.02c-0.33,0,0.31,0.04,0.34,0.05
              c-0.2-0.09-0.49-0.09-0.71-0.16c-0.13-0.04-0.25-0.08-0.38-0.12c-0.28-0.1-0.49-0.35,0.06,0.04c-0.44-0.31-0.97-0.5-1.42-0.82
              c-0.05-0.04-0.1-0.07-0.15-0.11c-0.32-0.22,0.19,0.14,0.2,0.16c-0.06-0.09-0.21-0.17-0.3-0.25c-0.25-0.22-0.49-0.44-0.72-0.68
              c-0.2-0.21-0.4-0.43-0.59-0.65c-0.06-0.08-0.42-0.53-0.15-0.18c0.26,0.34-0.07-0.11-0.14-0.19c-0.38-0.54-0.72-1.11-1.03-1.7
              c-0.08-0.15-0.15-0.3-0.22-0.45c-0.04-0.09-0.25-0.55-0.08-0.17c0.17,0.38-0.05-0.13-0.09-0.24c-0.07-0.18-0.13-0.37-0.19-0.55
              c-0.2-0.63-0.32-1.28-0.46-1.93c-0.06-0.27,0.04,0.32,0.04,0.3c0-0.09-0.02-0.2-0.03-0.29c-0.01-0.15-0.03-0.29-0.04-0.44
              c-0.02-0.37-0.03-0.74-0.02-1.11c0.01-0.32,0.08-0.65,0.08-0.96c0.01,0.33-0.07,0.45,0,0.07c0.04-0.2,0.08-0.39,0.13-0.59
              c0.09-0.35,0.19-0.69,0.31-1.02c0.08-0.22,0.2-0.37,0.02-0.07c0.14-0.23,0.24-0.49,0.39-0.72c0.05-0.08,0.11-0.17,0.17-0.25
              c0.15-0.24-0.07,0.1-0.09,0.12c0.06-0.06,0.57-0.51,0.29-0.32c-0.26,0.19-0.01,0.02,0.04-0.01c0.37-0.23-0.05,0.01-0.13,0.04
              c0.3-0.1-0.01,0,0-0.03c-0.19,0.02-0.22,0.02-0.08,0.02c0.14,0,0.11-0.01-0.07-0.03c-0.05-0.01-0.11-0.02-0.16-0.02
              c0.22,0.07,0.23,0.06,0.06-0.01c-0.28-0.06,0.06,0.04,0.16,0.1c0.23,0.14-0.01-0.01-0.04-0.04c0,0,0.57,0.57,0.29,0.27
              c-0.21-0.22-0.03-0.04,0.01,0.03c0.07,0.12,0.15,0.49,0.1,0.14c-0.06-0.42-0.02,0.25,0.06,0.3c-0.07-0.05-0.01-0.63-0.04-0.2
              c0,0.04-0.03,0.29-0.01,0.31c-0.02-0.02,0.12-0.52,0.04-0.28c-0.04,0.13-0.06,0.26-0.09,0.39c-0.02,0.08-0.06,0.16-0.08,0.23
              c-0.05,0.28,0.27-0.52,0.02-0.06c-0.1,0.18-0.19,0.36-0.29,0.53c-0.05,0.08-0.09,0.15-0.14,0.22c-0.01,0.02-0.33,0.5-0.12,0.19
              c0.18-0.27,0.02-0.02-0.03,0.03c-0.1,0.12-0.2,0.23-0.3,0.35c-0.31,0.34-0.63,0.65-0.98,0.95c-0.07,0.06-0.14,0.11-0.2,0.17
              c-0.22,0.21,0.4-0.28,0.02-0.02c-0.18,0.13-0.36,0.26-0.55,0.37c-0.33,0.21-0.67,0.39-1.03,0.55c-1.2,0.53-1.57,2.38-0.9,3.42
              C651.19,258.03,652.52,258.24,653.8,257.68L653.8,257.68z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M663.02,254.68c2.21,3.57,3.76,7.46,6.35,10.79c1.55,1.99,4.95-0.17,4.18-2.43c-1.75-5.13-4.55-10.58-2.2-15.99
              c1.94-4.45,6.04-8.49,10.74-9.88c3.08-0.91,1.77-5.74-1.33-4.82c-5.93,1.75-11.17,6.57-13.73,12.17
              c-1.51,3.29-1.91,6.7-1.31,10.26c0.56,3.31,1.93,6.42,3.01,9.58c1.39-0.81,2.79-1.62,4.18-2.43c-2.3-2.95-3.61-6.6-5.57-9.78
              C665.65,249.42,661.33,251.93,663.02,254.68L663.02,254.68z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M702.81,257.06c0.66,3.78,1.44,7.53,2.07,11.31c1.61-0.44,3.21-0.89,4.82-1.33c-0.96-4.06-0.87-8.98,0.94-12.59
              c1.93-3.86,5.05-6.47,9.44-7.04c1.34-0.17,2.5-1.03,2.5-2.5c0-1.22-1.15-2.67-2.5-2.5c-5.87,0.75-10.95,3.88-13.64,9.27
              c-2.57,5.16-2.88,11.13-1.56,16.68c0.31,1.31,1.81,2.09,3.08,1.75c1.41-0.39,1.97-1.74,1.75-3.08c-0.63-3.78-1.42-7.53-2.07-11.31
              c-0.23-1.33-1.86-2.08-3.08-1.75C703.14,254.37,702.57,255.73,702.81,257.06L702.81,257.06z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M719.26,256.4c0.24,2.26,0.7,4.5,1.32,6.69c0.69,2.44,1.57,5.01,3.11,7.06c1.74,2.32,4.41,3.66,7.2,2.23
              c3.04-1.56,4.79-5.31,5.63-8.44c1.12-4.16,1.41-9.1-0.06-13.21c-0.45-1.27-1.7-2.12-3.08-1.75c-1.31,0.36-2.1,1.77-1.75,3.08
              c1.77,6.46,3.9,12.82,5.96,19.19c0.41,1.28,1.73,2.11,3.08,1.75c1.26-0.35,2.16-1.78,1.75-3.08c-2.05-6.38-4.18-12.73-5.96-19.19
              c-1.61,0.44-3.21,0.89-4.82,1.33c0.24,0.67,0.42,1.35,0.55,2.05c0.03,0.17,0.06,0.33,0.08,0.5c-0.07-0.42,0.02,0.13,0.03,0.23
              c0.05,0.49,0.08,0.98,0.1,1.47c0.05,1.61-0.08,3.3-0.31,4.58c-0.15,0.8-0.33,1.58-0.57,2.36c-0.11,0.37-0.24,0.73-0.37,1.09
              c-0.03,0.1-0.24,0.59-0.07,0.2c-0.1,0.22-0.2,0.43-0.31,0.64c-0.3,0.59-0.65,1.14-1.04,1.67c-0.25,0.34,0.37-0.4-0.08,0.09
              c-0.09,0.1-0.17,0.2-0.26,0.3c-0.2,0.22-0.43,0.41-0.65,0.62c0.51-0.49-0.04-0.02-0.18,0.09c-0.39,0.32,0.51-0.09-0.06,0.03
              c-0.63,0.14,0.57-0.04,0.03,0.01c-0.03,0-0.28,0-0.28-0.01c0.15,0.02,0.3,0.04,0.45,0.06c-0.07-0.01-0.14-0.03-0.22-0.05
              c-0.02-0.04-0.21-0.02-0.21-0.07c0-0.11,0.58,0.4,0.29,0.11c-0.07-0.07-0.61-0.46-0.25-0.14c0.36,0.32-0.14-0.16-0.23-0.24
              c-0.2-0.21-0.5-0.65-0.15-0.14c-0.57-0.84-1.05-1.7-1.47-2.64c-0.15-0.34,0.22,0.58,0.01,0.02c-0.04-0.11-0.09-0.22-0.13-0.34
              c-0.1-0.26-0.19-0.51-0.28-0.77c-0.19-0.55-0.37-1.11-0.54-1.67c-0.35-1.17-0.64-2.36-0.88-3.56c-0.1-0.48-0.19-0.96-0.27-1.45
              c-0.01-0.08-0.06-0.62-0.1-0.63c0.08,0.02,0.08,0.71,0.04,0.27c-0.01-0.12-0.03-0.23-0.04-0.35c-0.14-1.34-1.05-2.5-2.5-2.5
              C720.52,253.9,719.12,255.05,719.26,256.4L719.26,256.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M750.72,247.48c-2.78-2.14-7.52-1.87-9.89,0.79c-2.57,2.89-2.05,7.4,1.26,9.45c2.33,1.43,5.24,1.54,7.81,2.27
              c0.17,0.05,0.52,0.22-0.12-0.07c0.26,0.12,0.51,0.24,0.76,0.37c0.4,0.21,0.79,0.45,1.15,0.72c-0.28-0.21,0.14,0.13,0.2,0.18
              c0.16,0.14,0.31,0.29,0.45,0.44c0.01,0.01,0.52,0.63,0.23,0.24c0.14,0.19,0.27,0.39,0.38,0.6c0.03,0.06,0.31,0.73,0.14,0.24
              c0.05,0.14,0.1,0.29,0.14,0.43c0.02,0.09,0.11,0.79,0.07,0.23c0.02,0.23,0.01,0.46,0.01,0.69c0,0.11-0.09,0.46,0,0.04
              c-0.05,0.24-0.12,0.47-0.19,0.71c-0.23,0.72,0.11-0.17-0.11,0.24c-0.15,0.29-0.31,0.58-0.51,0.84c-0.22,0.3-0.72,0.84-1.11,1.15
              c0.2-0.16-0.48,0.32-0.42,0.28c-0.09,0.06-0.73,0.31-0.3,0.17c-0.09,0.03-0.8,0.16-0.21,0.1c-0.21,0.02-0.43,0.02-0.64,0
              c0.6,0.04-0.05-0.05-0.17-0.08c-0.52-0.12,0.25,0.21-0.21-0.09c-0.19-0.12-0.39-0.23-0.58-0.36c0.37,0.26-0.21-0.19-0.24-0.21
              c-0.38-0.35-0.73-0.73-1.05-1.13c0.25,0.31-0.29-0.41-0.26-0.36c-0.16-0.23-0.31-0.46-0.46-0.7c-0.36-0.57-0.68-1.15-0.98-1.75
              c-0.62-1.2-2.3-1.55-3.42-0.9c-1.22,0.71-1.52,2.22-0.9,3.42c1.89,3.66,5.22,8,9.9,7.02c4.48-0.94,7.93-6.45,6.53-10.85
              c-1.19-3.74-4.76-6.09-8.45-6.86c-1.33-0.28-2.68-0.47-3.97-0.89c-0.17-0.05-0.33-0.12-0.5-0.18c-0.19-0.06-0.27-0.22,0.12,0.05
              c-0.21-0.15-0.55-0.35-0.79-0.44c-0.26-0.1,0.37,0.42,0.07,0.05c-0.09-0.11-0.22-0.23-0.32-0.33c0.41,0.41,0.2,0.25,0.09,0.07
              c-0.34-0.52,0.22,0.39-0.03-0.06c-0.1-0.18-0.03-0.66-0.03-0.01c0-0.19,0-0.38-0.01-0.57c-0.01-0.59-0.17,0.41,0.03-0.17
              c0.08-0.23,0.22-0.54,0.02-0.16c0.09-0.17,0.19-0.33,0.29-0.5c-0.33,0.52,0.12-0.07,0.24-0.19c-0.08,0.08-0.43,0.25-0.05,0.06
              c0.14-0.07,0.86-0.37,0.25-0.17c0.12-0.04,0.24-0.08,0.36-0.12c0.15-0.04,0.96-0.14,0.29-0.09c0.4-0.03,0.78-0.03,1.18,0
              c-0.64-0.05-0.03,0.01,0.11,0.04c0.19,0.05,0.37,0.11,0.56,0.17c-0.57-0.18-0.03-0.01,0.1,0.07c0.22,0.12,0.43,0.27,0.63,0.42
              c1.07,0.83,2.8,0.16,3.42-0.9C752.37,249.61,751.79,248.31,750.72,247.48L750.72,247.48z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M756.84,237.47c1.59,11.14,4.82,22.11,4.64,33.44c-0.05,3.22,4.95,3.22,5,0c0.19-11.78-3.16-23.2-4.82-34.77
              c-0.19-1.34-1.89-2.07-3.08-1.75C757.15,234.79,756.65,236.13,756.84,237.47L756.84,237.47z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M759.24,256.44c5.55-1.67,11.19-2.98,16.89-4.05c1.33-0.25,2.08-1.85,1.75-3.08c-0.38-1.39-1.74-2-3.08-1.75
              c-5.69,1.07-11.34,2.39-16.89,4.05c-1.29,0.39-2.11,1.75-1.75,3.08C756.52,255.98,757.94,256.83,759.24,256.44L759.24,256.44z"
          />
        </g>
      </g>
      <g>
        <path d="M317.77,278.74c-0.05,2.7,0.84,5.35,0.71,8.02" />
      </g>
      <g>
        <g>
          <path d="M327.88,286.57c1.93,0,1.93-3,0-3C325.95,283.57,325.95,286.57,327.88,286.57L327.88,286.57z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M315.57,276.32c-0.03,1.4-0.04,2.82,0.3,4.19c0.32,1.3,0.69,2.6,1.14,3.86c0.26,0.74,1.03,1.31,1.85,1.05
              c0.73-0.23,1.33-1.05,1.05-1.85c-0.43-1.22-0.77-2.47-1.1-3.71c-0.08-0.3-0.15-0.61-0.19-0.92c0.04,0.31-0.01-0.15-0.01-0.17
              c-0.01-0.17-0.02-0.33-0.03-0.5c-0.03-0.65-0.01-1.3,0-1.94c0.02-0.78-0.7-1.54-1.5-1.5
              C316.25,274.86,315.59,275.48,315.57,276.32L315.57,276.32z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M314.84,275.64c-0.43,2.4-0.01,4.69,0.69,6.99c0.33,1.1,0.67,2.19,1.06,3.27c0.41,1.12,0.93,2.2,1.38,3.3
              c0.31,0.75,1,1.28,1.85,1.05c0.71-0.19,1.36-1.09,1.05-1.85c-0.83-2.03-1.65-4-2.29-6.1c-0.58-1.9-1.2-3.86-0.84-5.87
              c0.14-0.8-0.22-1.62-1.05-1.85C315.96,274.39,314.99,274.84,314.84,275.64L314.84,275.64z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M316.16,276.83c1.05-0.59,2.4-0.75,3.53-1.19c1.34-0.52,2.66-1.07,4.02-1.57c1.39-0.5,2.78-0.98,4.16-1.5
              c0.68-0.26,1.34-0.53,2.03-0.76c0.26-0.09,1-0.17,1.18-0.35c-0.17,0.17-0.5-1.48-0.76-0.97c-0.35,0.73-0.2,1.62,0.54,2.05
              c0.66,0.39,1.7,0.19,2.05-0.54c0.4-0.82,0.63-1.72,0.13-2.56c-0.44-0.75-1.33-1.04-2.16-0.98c-0.96,0.08-1.91,0.47-2.81,0.81
              c-0.95,0.36-1.91,0.72-2.87,1.07c-1.85,0.66-3.68,1.33-5.51,2.07c-0.85,0.34-1.71,0.68-2.6,0.92c-0.85,0.23-1.68,0.46-2.45,0.89
              C312.96,275.18,314.47,277.77,316.16,276.83L316.16,276.83z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M320.94,287.32c0.06-0.01,0.62-0.09,0.27-0.05c0.14-0.02,0.27-0.03,0.41-0.04c0.32-0.03,0.64-0.05,0.96-0.07
              c0.71-0.05,1.41-0.12,2.11-0.24c0.31-0.05,0.62-0.12,0.94-0.18c0.06-0.01,0.55-0.08,0.24-0.04c0.14-0.02,0.28-0.03,0.42-0.05
              c0.75-0.09,1.49-0.16,2.23-0.3c0.77-0.14,1.28-1.12,1.05-1.85c-0.27-0.83-1.02-1.2-1.85-1.05c-0.19,0.03-0.37,0.07-0.56,0.09
              c0.27-0.04-0.09,0.01-0.17,0.02c-0.33,0.04-0.66,0.07-0.99,0.11c-0.71,0.09-1.41,0.22-2.11,0.35c-0.15,0.03-0.29,0.05-0.44,0.07
              c0.29-0.04-0.2,0.01-0.26,0.02c-0.3,0.02-0.59,0.04-0.89,0.06c-0.73,0.05-1.45,0.12-2.17,0.26c-0.77,0.15-1.28,1.12-1.05,1.85
              C319.37,287.09,320.12,287.47,320.94,287.32L320.94,287.32z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M322.03,291.25c4.73-1.17,8.75-4.09,13.04-6.26c0.72-0.37,0.93-1.38,0.54-2.05c-0.43-0.74-1.33-0.9-2.05-0.54
              c-4.06,2.05-7.85,4.85-12.32,5.95C319.36,288.83,320.15,291.72,322.03,291.25L322.03,291.25z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M323.14,289.26c0.13-0.09,0.27-0.17,0.4-0.26c0.31-0.21,0.66-0.48,0.82-0.83c0.14-0.23,0.2-0.49,0.2-0.76
              c0.01-0.21-0.04-0.4-0.13-0.58c-0.06-0.19-0.16-0.35-0.31-0.48c-0.19-0.19-0.41-0.31-0.66-0.39c-0.19-0.03-0.39-0.07-0.59-0.06
              c-0.2,0.01-0.4,0.04-0.6,0.07c-0.15,0.02-0.3,0.05-0.45,0.07c0.13-0.02,0.27-0.04,0.4-0.05c-0.04,0-0.07,0.01-0.11,0.01
              c0.13,0.98,0.27,1.96,0.4,2.95c0.58-0.19,1.14-0.47,1.7-0.71c0.17-0.09,0.32-0.22,0.42-0.39c0.13-0.14,0.22-0.31,0.27-0.51
              c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56c-0.08-0.1-0.16-0.2-0.23-0.3c-0.19-0.19-0.41-0.31-0.66-0.39
              c-0.13-0.02-0.27-0.04-0.4-0.05c-0.27,0-0.52,0.07-0.76,0.2c-0.27,0.12-0.53,0.24-0.8,0.35c0.12-0.05,0.24-0.1,0.36-0.15
              c-0.18,0.07-0.36,0.14-0.54,0.21c-0.2,0.05-0.37,0.14-0.51,0.27c-0.16,0.11-0.29,0.25-0.39,0.42c-0.05,0.12-0.1,0.24-0.15,0.36
              c-0.06,0.2-0.07,0.4-0.03,0.6c0,0.14,0.03,0.26,0.1,0.38c0.06,0.19,0.16,0.35,0.31,0.48c0.1,0.08,0.2,0.16,0.3,0.23
              c0.23,0.14,0.49,0.2,0.76,0.2c0.33-0.03,0.65-0.1,0.98-0.14c-0.13,0.02-0.27,0.04-0.4,0.05c0.12-0.01,0.23-0.02,0.35-0.01
              c-0.13-0.02-0.27-0.04-0.4-0.05c0.01,0,0.01,0,0.02,0c-0.3-0.73-0.6-1.47-0.9-2.2c-0.01,0.03-0.03,0.06-0.05,0.08
              c0.08-0.1,0.16-0.2,0.23-0.3c-0.04,0.06-0.1,0.1-0.15,0.15c0.1-0.08,0.2-0.16,0.3-0.23c-0.15,0.12-0.33,0.2-0.49,0.32
              c-0.17,0.09-0.32,0.22-0.42,0.39c-0.13,0.14-0.22,0.31-0.27,0.51c-0.06,0.2-0.07,0.4-0.03,0.6c0.01,0.2,0.07,0.39,0.18,0.56
              c0.08,0.1,0.16,0.2,0.23,0.3c0.19,0.19,0.41,0.31,0.66,0.39c0.13,0.02,0.27,0.04,0.4,0.05
              C322.66,289.46,322.91,289.4,323.14,289.26L323.14,289.26z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264.09,202.48c1.79,7.96,4.15,15.84,5.18,23.95c1.07,8.49,1.73,17.17,1.5,25.73c-0.05,1.93,2.95,1.93,3,0
              c0.24-8.71-0.43-17.56-1.56-26.19c-1.08-8.23-3.41-16.2-5.23-24.28C266.56,199.8,263.67,200.59,264.09,202.48L264.09,202.48z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264.88,210.49c-0.1,4.07,0.69,8.08,1.31,12.09c0.61,3.96,0.57,7.88,0.58,11.88c0.02,8.49,0.18,17,0.63,25.48
              c0.1,1.92,3.1,1.93,3,0c-0.45-8.48-0.62-16.98-0.63-25.48c-0.01-3.95,0.02-7.82-0.55-11.75c-0.59-4.07-1.44-8.09-1.34-12.22
              C267.93,208.56,264.93,208.56,264.88,210.49L264.88,210.49z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264.22,225.44c0.83,4.44,1.11,8.95,1.33,13.45c0.23,4.54,0.21,9.12-0.04,13.66c-0.46,8.15-4.97,15.19-8.78,22.14
              c-0.93,1.69,1.66,3.21,2.59,1.51c4.02-7.33,8.56-14.66,9.16-23.22c0.34-4.82,0.29-9.72,0.05-14.55
              c-0.24-4.62-0.57-9.24-1.42-13.79C266.76,222.75,263.87,223.55,264.22,225.44L264.22,225.44z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.42,196.96c2.62,6.3,4.73,12.82,6.14,19.5c1.32,6.29,2.18,12.77,2.87,19.16c0.68,6.35,1,12.9-0.23,19.2
              c-1.07,5.5-3.39,10.69-6.68,15.22c-3.62,4.99-8.58,9.13-14.31,11.47c-1.76,0.72-0.99,3.62,0.8,2.89
              c10.89-4.46,19.03-14.25,22.29-25.49c1.85-6.39,1.96-13.18,1.45-19.76c-0.51-6.64-1.51-13.3-2.62-19.87
              c-1.33-7.94-3.74-15.69-6.82-23.12C257.59,194.4,254.68,195.17,255.42,196.96L255.42,196.96z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M242.51,161.3c3.04,0.44,5.8,1.71,8.42,3.27c2.52,1.5,5.03,3.13,7.22,5.08c4.46,3.97,8.33,8.85,11.59,13.84
              c3.27,5.02,6,10.4,8.27,15.94c2.32,5.68,4.43,11.55,5.38,17.63c1.14,7.36,0.82,14.86,0.81,22.28c0,1.93,3,1.93,3,0
              c0.01-6.86,0.25-13.75-0.58-20.58c-0.79-6.56-2.8-12.79-5.23-18.91c-4.57-11.55-10.99-22.6-20-31.28
              c-2.47-2.38-5.29-4.34-8.2-6.14c-3.03-1.87-6.32-3.51-9.88-4.03c-0.81-0.12-1.6,0.2-1.85,1.05
              C241.26,160.16,241.71,161.18,242.51,161.3L242.51,161.3z"
          />
        </g>
      </g>
    </svg>
  );
}
