import * as React from "react";
import { Helmet } from "react-helmet";

import "../style/global.css";
import Layout from "../components/layout";
import { Title } from "../components/customIcons";

// Index Page
function IndexPage() {
  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek</title>
      </Helmet>
      <div className="flex w-full justify-center">
        <Title className="h-full fill-black transition duration-500 hover:fill-yellow-polle hover:scale-110 hover:svg-shadow" />
      </div>
    </Layout>
  );
}

export default IndexPage;
